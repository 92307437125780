(<template>
  <FullCalendar ref="timelineCalendar"
                :options="calendarOptions" />
</template>)

<script>
  import {mapGetters} from 'vuex';
  // can't be imported on the server side
  import FullCalendar from '@fullcalendar/vue';
  import interactionPlugin from '@fullcalendar/interaction';
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

  export default {
    components: {FullCalendar},
    props: {
      events: {
        type: Array,
        default: () => []
      },
      view: {
        type: String,
        default: ''
      },
      activeDate: {
        type: [String, Object],
        default: ''
      },
      selectable: {
        type: [Boolean, undefined],
        default: false
      },
      resourcesTitle: {
        type: String,
        default: ''
      },
      resources: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        calendarOptions: {
          plugins: [
            resourceTimelinePlugin,
            interactionPlugin
          ],
          locale: this.$language.current,
          initialResources: this.resources,
          resourceAreaHeaderContent: this.resourcesTitle,
          resourceLabelDidMount: this.getResourceTitle,
          events: this.events,
          timeZone: this.userServerTimeZoneName,
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          initialView: this.getResourceTimelineView(),
          initialDate: this.activeDate,
          headerToolbar: '',
          slotLabelFormat: [{
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          }, {
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false
          }],
          height: '100%',
          firstDay: 1,
          nowIndicator: true,
          slotEventOverlap: true,
          selectable: this.selectable,
          selectMirror: true,
          selectLongPressDelay: true,
          longPressDelay: 1,
          selectConstraint: {start: '00:00', end: '24:00'},
          unselectAuto: true,
          selectOverlap: this.getSelectOverlap,
          eventInteractive: true,
          select: this.selectPeriod
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userServerTimeZoneName',
        'userUid'
      ]),
    },
    methods: {
      selectPeriod(selectionInfo) {
        this.$emit('period-selected', selectionInfo);
      },
      getSelectOverlap(event) {
        return event.extendedProps.selectOverlap || false;
      },
      getResourceTitle(renderInfo) {
        const innerHTML = renderInfo.resource.extendedProps?.rawHTMLTitle;

        const element = renderInfo.el.querySelector('.fc-datagrid-cell-main');
        if (element) {
          element.innerHTML = innerHTML;
        }
      },
      getResourceTimelineView() {
        switch (this.view) {
          case 'week':
            return 'resourceTimelineWeek';
          case 'day':
            return 'resourceTimelineDay';
        }
      }
    },
    mounted() {
      this.$emit('catch-calendar-instance', this.$refs.timelineCalendar.getApi());
    }
  };
</script>
